import { useLazyQuery } from "@apollo/client";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PartyScribbles from "../../assets/imgs/party-scribbles.png";
import GET_ORGANIZATION_BY_ID from "../../graphql/queries/GetOrganization";
import { useAuth } from "../../hooks";
import { BasicButton } from "../common/buttons/BasicButton";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import PageTitle from "../layout/PageTitle";
import TeamModal from "../team/TeamModal";

const PAGE_DESCRIPTION =
  "Your teams are listed below. Click on a team to manage its members.";

const OrganizationPage = ({
  refetchTeams,
  loadingTeams,
  userTeams,
  userTeamsError,
}) => {
  //state
  const [teams, setTeams] = useState([]);
  const [isSortReverse, setIsSortReverse] = useState(false);
  const [currentSort, setCurrentSort] = useState("");
  const [isShowingCreateTeamModal, setIsShowingCreateTeamModal] =
    useState(false);

  //hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { orgUuid, isOrgAdmin } = useAuth();

  //queries
  const [getOrganization, { loading, error, data, refetch }] =
    useLazyQuery(GET_ORGANIZATION_BY_ID, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setTeams(data.organization.teams);
      },
    });

  //functions
  const hideCreateTeamModal = () => {
    setIsShowingCreateTeamModal(false);
  };

  const showCreateTeamModal = () => {
    setIsShowingCreateTeamModal(true);
  };

  const fetchOrganization = useCallback(() => {
    if (orgUuid) {
      getOrganization({ variables: { uuid: orgUuid } });
    } else {
      setTeams(userTeams);
    }
  }, [orgUuid, getOrganization, userTeams]);

  const sortTeams = useCallback((teams, sortBy, reverse) => {
    return [...teams].sort((a, b) => {
      const aValue = a[sortBy] ?? "";
      const bValue = b[sortBy] ?? "";

      if (typeof aValue === "number" || typeof bValue === "number") {
        return reverse ? bValue - aValue : aValue - bValue;
      } else {
        return reverse
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      }
    });
  }, []);

  //effects
  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization, location.search, orgUuid]);

  useEffect(() => {
    if (data?.organization?.teams || userTeams) {
      const queryParams = new URLSearchParams(location.search);
      const savedSortBy = queryParams.get("sortBy");
      const savedIsSortReverse =
        queryParams.get("isSortReverse") === "true";
      const savedScrollPosition = queryParams.get("scrollPosition");

      if (data?.organization?.teams) {
        setTeams(data.organization.teams);
      } else if (userTeams) {
        setTeams(userTeams);
      }
      if (savedSortBy) {
        setCurrentSort(savedSortBy);
        setIsSortReverse(savedIsSortReverse);
      }

      if (savedScrollPosition) {
        //the setTimeout seems unnecessary, but it places the scroll logic into the event queue
        //you need the call stack to clear before you can scroll so this is needed
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }, 0);
      }
    }
  }, [data, userTeams, location.search]);

  const sortedTeams = useMemo(() => {
    if (currentSort) {
      return sortTeams(teams, currentSort, isSortReverse);
    }
    return teams;
  }, [teams, currentSort, isSortReverse, sortTeams]);

  //loading and error states
  if (loading || loadingTeams) {
    return (
      <FullScreenLoading loadingTrigger={loading || loadingTeams} />
    );
  }

  if (error || userTeamsError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h2>
          Error:{" "}
          {error?.message ? error.message : userTeamsError?.message}
        </h2>
        <p>Please try again later.</p>
      </div>
    );
  }

  const handleSort = (sortBy) => {
    setIsSortReverse((prevIsSortReverse) => !prevIsSortReverse);
    setCurrentSort(sortBy);
  };

  const handleRowClick = (teamUuid) => {
    const queryParams = new URLSearchParams({
      sortBy: currentSort,
      isSortReverse: isSortReverse.toString(),
      scrollPosition: window.scrollY.toString(),
    }).toString();
    navigate(`/teams/${teamUuid}?${queryParams}`);
  };

  const handleRefetch = () => {
    if (orgUuid) {
      refetch({ variables: { uuid: orgUuid } }).then(({ data }) => {
        if (data) {
          setTeams(data.organization.teams);
        }
      });
    } else {
      refetchTeams();
    }
  };

  //render
  return (
    <>
      <TeamModal
        isOpen={isShowingCreateTeamModal}
        onClose={hideCreateTeamModal}
        orgUuid={orgUuid}
        isCreatingTeam={true}
        refetch={handleRefetch}
      />
      <PageTitle title="Manage Your Teams">
        {isOrgAdmin && (teams?.length > 0 || sortedTeams?.length) && (
          <BasicButton
            importance="secondary"
            onClick={showCreateTeamModal}
          >
            Create New Team
          </BasicButton>
        )}
      </PageTitle>
      <h4 className="font-normal text-gray-400 dark:text-gray-500 mt-2 rounded-lg p-2 mb-2">
        {PAGE_DESCRIPTION}
      </h4>

      {sortedTeams?.length === 0 && userTeams?.length === 0 ? (
        <div className="flex flex-col items-center bg-gray-100/70 dark:bg-gray-700/30 border-2 border-dashed border-gray-50 dark:border-gray-700/50 rounded-lg py-10 px-2 w-full my-4 dark:hover:bg-gray-700/50 transition-all cursor-pointer hover:bg-indigo-100 hover:border-indigo-200">
          <img
            className="flex self-center"
            width="100px"
            src={PartyScribbles}
            alt="Party Scribbles"
          />
          <h4 className="mt-4 mb-2">No one's here yet...</h4>
          <h5 className="text-gray-700 max-w-3xl">
            Looks like the party hasn&apos;t started yet. Contact
            support to add your first team!
          </h5>
        </div>
      ) : (
        <div className="bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-2 w-full my-4">
          <table className="table-auto w-full dark:text-gray-300 text-left">
            <thead>
              <tr className="border-b dark:border-gray-700/50">
                <TableHeader
                  title="Team Name"
                  sortBy="name"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="Location"
                  sortBy="location"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="# of Users"
                  sortBy="numUsers"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="Created At"
                  sortBy="createdAt"
                  handleSort={handleSort}
                />
              </tr>
            </thead>
            <tbody>
              {sortedTeams?.map((team, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(team.uuid)}
                  className="cursor-pointer even:bg-transparent dark:odd:bg-gray-800 odd:bg-white dark:even:bg-transparent hover:bg-indigo-100 dark:hover:bg-indigo-700/50 rounded-lg"
                >
                  <td className="py-2 pl-2">
                    <h5>{team.name}</h5>
                  </td>
                  <td className="py-2">
                    <h5>
                      {team.location
                        ? team.location
                        : "Location not specified"}
                    </h5>
                  </td>
                  <td className="py-2">
                    <h5>{team.numUsers}</h5>
                  </td>
                  <td className="py-2">
                    <h5>
                      {moment(team.createdAt)?.isValid()
                        ? moment(team.createdAt).format("MMM Do YYYY")
                        : "Date unavailable"}
                    </h5>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

function TableHeader({ sortBy, title, handleSort }) {
  return (
    <th
      className="font-semibold text-sm cursor-pointer"
      onClick={() => handleSort(sortBy)}
    >
      {title} <FontAwesomeIcon icon={faSort} />
    </th>
  );
}

export default OrganizationPage;
