import { useMutation } from "@apollo/client";
import { TRANSFER_NOTES } from "@graphql/mutations/TransferNotes.js";
import mixpanel from "mixpanel-browser";
import { useState } from "react";

import { useTeam, useAuth } from "../../../hooks";
import { alert } from "../Alert";
import { TransferNotesModal } from "../modals/TransferNotesModal";

export function SimpleNoteTransferModal({
  note,
  shouldShowTransferModal,
  setShouldShowTransferModal,
}) {
  const { userUuid } = useAuth();
  const { currentTeam } = useTeam();
  const [newUser, setNewUser] = useState(userUuid);
  const [newTeam, setNewTeam] = useState(currentTeam);
  const [transferNotes] = useMutation(TRANSFER_NOTES);
  const selectedNoteUuids = note?.uuid ? { [note.uuid]: true } : {};
  const confirmAction = async () => {
    try {
      const { data } = await transferNotes({
        variables: {
          noteUuids: Object.keys(selectedNoteUuids),
          newTeamUuid: newTeam?.uuid,
          newOwnerUuid: newUser,
        },
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "inboxTabCount",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "inboxNotes",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "dashboardInboxNotes",
          });
        },
      });

      if (data.transferNoteOwnership.failedNotes?.length) {
        alert(
          "error",
          "Failed to transfer. Check your internet connection and try again in the inbox.",
        );
        mixpanel.track("Failed to transfer notes", {
          transferredNoteUuids: selectedNoteUuids,
          failedNotes: data.transferNoteOwnership.failedNotes,
          newNoteOwner: newUser,
          newNoteTeam: newTeam?.uuid,
          previousNoteOwner: localStorage.getItem("user_uuid"),
        });
      } else {
        alert("success", "Notes transferred successfully!");
        mixpanel.track("Transferred Note", {
          transferredNoteUuids: selectedNoteUuids,
          newNoteOwner: newUser,
          newNoteTeam: newTeam?.uuid,
          previousNoteOwner: localStorage.getItem("user_uuid"),
        });
      }
    } catch (error) {
      alert(
        "error",
        "Unsuccessful Note Transfer. Please try again later. If the issue persists, please contact support.",
      );
      mixpanel.track("Transfer Note Error", {
        transferredNoteUuids: selectedNoteUuids,
        newNoteOwner: newUser,
        previousNoteOwner: localStorage.getItem("user_uuid"),
        transferNoteError: error,
      });
    } finally {
      setShouldShowTransferModal(false);
    }
  };
  return (
    <TransferNotesModal
      notes={[note]}
      shouldShowTransferModal={shouldShowTransferModal}
      setShouldShowTransferModal={setShouldShowTransferModal}
      confirmAction={confirmAction}
      loading={false}
      newUser={newUser}
      setNewUser={setNewUser}
      newTeam={newTeam}
      setNewTeam={setNewTeam}
      selectedNoteUuids={selectedNoteUuids}
      wasAutoPrompted={true}
    ></TransferNotesModal>
  );
}
