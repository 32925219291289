import SelectTeam from "@components/account/Teams/SelectTeam";
import TeamUserDropdown from "@components/layout/header/TeamUserDropdown";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Twemoji from "@src/Twemoji";
import { React } from "react";

import { ConfirmModal } from "./PopupModals";
import { truncate } from "../../../utils";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";

export function TransferNotesModal({
  notes,
  shouldShowTransferModal,
  setShouldShowTransferModal,
  confirmAction,
  loading,
  newUser,
  setNewUser,
  newTeam,
  setNewTeam,
  selectedNoteUuids,
  wasAutoPrompted = false,
}) {
  const handleUserChange = (e) => {
    setNewUser(e.target.value);
  };
  const numSelectedNotes = selectedNoteUuids?.length || 0;
  return (
    <ConfirmModal
      shouldShow={shouldShowTransferModal}
      hideModal={() => setShouldShowTransferModal(false)}
      title={
        numSelectedNotes > 1
          ? "Transfer these notes?"
          : "Transfer this note?"
      }
      confirmText="Transfer"
      confirmAction={confirmAction}
      cancelAction={() => setShouldShowTransferModal(false)}
      disabled={loading}
    >
      <h5>
        Which team member would you like to{" "}
        <strong>
          transfer
          {numSelectedNotes > 1
            ? ` these ${numSelectedNotes} notes `
            : " this note "}
        </strong>
        to?
      </h5>
      <div className="flex flex-row items-center space-x-2 mt-4">
        <div className="flex-1">
          {notes?.map((note) => {
            if (
              Object.keys(selectedNoteUuids)?.includes(note?.uuid)
            ) {
              return (
                <h5
                  key={note?.uuid}
                  className="text-sm py-1 border p-2 rounded-lg dark:border-gray-700/50 bg-gray-50 dark:bg-gray-700 my-1"
                >
                  <Twemoji emoji={"📝"} />{" "}
                  <span className="font-medium">
                    {note?.patient?.name || ""}{" "}
                  </span>
                  <span>
                    <span className="font-medium">
                      {truncate(note?.title, 40)}
                    </span>{" "}
                    <NoteCreatedAt
                      createdAt={note?.createdAt}
                      size="sm"
                    />
                  </span>
                </h5>
              );
            }
          })}
        </div>
        <FontAwesomeIcon
          className="dark:text-gray-700"
          icon={faArrowCircleRight}
          size="2x"
        />
        <div className="flex-1">
          <SelectTeam
            currentTeam={newTeam}
            setCurrentTeam={setNewTeam}
          />
          <TeamUserDropdown
            propHandleChange={handleUserChange}
            currentTeam={newTeam}
            currentUser={newUser}
            setCurrentUser={setNewUser}
          />
        </div>
      </div>
      {wasAutoPrompted && (
        <div className="text-xs mt-4">
          If you don't want to be prompted to transfer newly created
          notes you can disable this feature in your Account Settings
          under Preferences.
        </div>
      )}
    </ConfirmModal>
  );
}
