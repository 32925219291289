import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDarkMode, useAuth } from "@hooks";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect } from "react";

const SelectTeam = ({
  currentTeam,
  setCurrentTeam,
  showLabel = true,
  propHandleChange,
}) => {
  const { userTeams } = useAuth();
  const [enabled] = useDarkMode();

  useEffect(() => {
    if (!currentTeam?.uuid && userTeams?.length) {
      setCurrentTeam(userTeams[0]);
    }
  }, [currentTeam, userTeams]);

  if (!userTeams?.length || userTeams.length <= 1) {
    return null;
  }

  const handleChange = async (event) => {
    const team = userTeams?.find(
      (team) => team?.uuid === event.target.value,
    );
    setCurrentTeam(team);
  };

  const handleSelectChange = propHandleChange || handleChange;

  return (
    <div
      className={`flex flex-row items-center dark:bg-gray-700 bg-gray-100 pl-3 rounded-lg text-indigo-500 relative`}
    >
      <FormControl
        sx={[
          {
            "&.MuiFormControl-root": {
              position: "initial",
            },
          },
        ]}
      >
        {showLabel && (
          <InputLabel
            size="small"
            shrink={true}
            sx={[
              {
                "&.MuiInputLabel-shrink": {
                  top: "-5px",
                  left: "-10px",
                  color: enabled ? "rgb(209 213 219)" : "black",
                  pl: 0,
                },
              },
              {
                pl: 1,
                fontSize: "13px",
                color: enabled ? "rgb(209 213 219)" : "black",
              },
            ]}
            id="select-team-label"
          >
            Team Name
          </InputLabel>
        )}
        <div>
          <FontAwesomeIcon icon={faPeopleArrows} />
          <Select
            label="Team Name"
            labelId="select-team-label"
            sx={[
              {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  pl: 0,
                  py: 1,
                },
              },

              {
                fontSize: "14px",
                color: enabled ? "rgb(209 213 219)" : "black",
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                borderRadius: "8px",
                flex: 1,
                width: 160,
              },
            ]}
            value={currentTeam?.uuid || userTeams[0]?.uuid}
            onChange={handleSelectChange}
            MenuProps={{
              PaperProps: {
                style: {
                  color: enabled ? "#f9fafb" : "black",
                  backgroundColor: enabled
                    ? "rgb(55 65 81)"
                    : "rgb(243 244 246)",
                },
              },
            }}
          >
            {userTeams?.map((team) => (
              <MenuItem
                key={team.uuid}
                value={team.uuid}
                sx={{
                  fontSize: "14px",
                }}
              >
                <h4 className="text-sm ml-2 font-medium truncate">
                  {team.name}
                </h4>
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    </div>
  );
};

export default SelectTeam;
