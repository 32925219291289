import { gql, useMutation } from "@apollo/client";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { AccountItem, AccountItemLine } from "./AccountItem.js";
import DarkModeToggle from "./DarkModeToggle.js";
import FeatureToggle from "./FeatureToggle.js";
import { useLocalStorage } from "../../hooks/use-local-storage.js";
import ToggleSwitch from "../common/buttons/ToggleSwitch";

const EDIT_ACCOUNT_PREFERENCES = gql`
  mutation editAccountPreferences(
    $shouldHidePresetTemplates: Boolean
  ) {
    editAccountPreferences(
      shouldHidePresetTemplates: $shouldHidePresetTemplates
    ) {
      ok
    }
  }
`;

// Define feature flag types
const FEATURE_FLAGS = {
  SONNET: "useSonnet",
  POINT_FORM: "usePointFormNotBullets",
  NAME_CORRECTION: "useNameCorrection",
};

const SEGMENT_KEYS = {
  SONNET: "opt-out-sonnet",
  POINT_FORM: "point-form-not-bullet-users",
  NAME_CORRECTION: "name-correction-toggle",
};

export default function PreferencesSettings({ data, refetch }) {
  const [shouldHidePresetTemplates, setShouldHidePresetTemplates] =
    useState(false);

  const [editAccountPreferences] = useMutation(
    EDIT_ACCOUNT_PREFERENCES,
  );

  useEffect(() => {
    setShouldHidePresetTemplates(
      data?.user?.account?.shouldHidePresetTemplates,
    );
  }, [data]);

  return (
    <div id="preferences">
      <AccountItem
        title="Preferences"
        icon={
          <FontAwesomeIcon icon={faToolbox} size="lg" color="gray" />
        }
      >
        <div className="flex flex-col flex-1 w-full space-y-4 divide-y dark:divide-gray-700">
          <FeatureToggle
            title="Opt out of NEW Medical Record AI"
            description="Do you want to revert to your old Medical Record AI? Turn this on!"
            flagKey={FEATURE_FLAGS.SONNET}
            segmentKey={SEGMENT_KEYS.SONNET}
            invertToggle={true}
          />
          <FeatureToggle
            title="Use Point Form for Consistency"
            description="Would you like your Assessment and Plan sections to consistently use point form?"
            flagKey={FEATURE_FLAGS.POINT_FORM}
            segmentKey={SEGMENT_KEYS.POINT_FORM}
          />
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Appearance:</strong> Using Scribenote late at
              night? Turn on dark mode and your eyes will thank you!
              👀
            </div>
            <DarkModeToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Capitalize SOAP Titles:</strong> When you copy
              an entire SOAP, this setting will determine if the S, O,
              A, and P titles are capitalized for readability.
            </div>
            <CapitalFormatToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Hide Preset Templates:</strong> This setting
              will turn off the default Scribenote templates.
            </div>
            <div>
              <ToggleSwitch
                toggleState={shouldHidePresetTemplates}
                setToggleState={setShouldHidePresetTemplates}
                toggleCallback={() => {
                  editAccountPreferences({
                    variables: {
                      shouldHidePresetTemplates:
                        // state has already changed, so save the opposite
                        !shouldHidePresetTemplates,
                    },
                  });

                  refetch();
                }}
              />
            </div>
          </AccountItemLine>
          <FeatureToggle
            title="Advanced Name Correction"
            description="Notice your
              name being misspelled consistently? Enable this to
              automatically correct name misspellings in your future
              notes based on your account name."
            flagKey={FEATURE_FLAGS.NAME_CORRECTION}
            segmentKey={SEGMENT_KEYS.NAME_CORRECTION}
          />
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Copy Note on Submit:</strong> Turning this
              setting on will automatically copy the note to your
              clipboard when you submit it.
            </div>
            <CopySubmitToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Transfer New Notes:</strong> Turning this
              setting on will open a modal to transfer any newly
              created note as soon as you create it.
            </div>
            <PromptNewNoteTransferToggle />
          </AccountItemLine>
        </div>
      </AccountItem>
    </div>
  );
}

function CapitalFormatToggle() {
  const [
    shouldUseCapitalSubtitlesCopy,
    setShouldUseCapitalSubtitlesCopy,
  ] = useLocalStorage("should-use-capital-subtitles-copy");

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <p className="text-sm">Don't Capitalize</p>
      <ToggleSwitch
        toggleState={shouldUseCapitalSubtitlesCopy}
        setToggleState={setShouldUseCapitalSubtitlesCopy}
      />
      <p className="text-sm">Capitalize</p>
    </div>
  );
}

function PromptNewNoteTransferToggle() {
  const [
    shouldPromptNewNoteTransfer,
    setShouldPromptNewNoteTransfer,
  ] = useLocalStorage("should-prompt-new-note-transfer", false);

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <ToggleSwitch
        toggleState={shouldPromptNewNoteTransfer}
        setToggleState={setShouldPromptNewNoteTransfer}
      />
    </div>
  );
}

function CopySubmitToggle() {
  const [shouldCopyOnSubmit, setShouldCopyOnSubmit] = useLocalStorage(
    "should-copy-on-submit",
    true,
  );

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <p className="text-sm">Don't Copy</p>
      <ToggleSwitch
        toggleState={shouldCopyOnSubmit}
        setToggleState={setShouldCopyOnSubmit}
      />
      <p className="text-sm">Copy</p>
    </div>
  );
}
