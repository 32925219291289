import { useLazyQuery } from "@apollo/client";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";

import { useAuth } from "./use-auth";
import { useLocalStorage } from "./use-local-storage";
import { alert } from "../components/common/Alert";
import GET_TEAM from "../graphql/queries/GetTeam";

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const auth = useAuth();
  const [currentTeam, _setCurrentTeam] = useState(() => {
    const savedTeam = localStorage.getItem("currentTeam");
    return savedTeam
      ? JSON.parse(savedTeam)
      : { name: "", users: [], uuid: null, id: null };
  });
  const [selectedTeamMembers, setSelectedTeamMembers] =
    useLocalStorage("selected-team-members", []);

  const setCurrentTeam = (team) => {
    if (team?.uuid != currentTeam?.uuid && currentTeam.uuid) {
      setSelectedTeamMembers([]);
    }
    _setCurrentTeam(team);
  };

  const [getTeam] = useLazyQuery(GET_TEAM, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCurrentTeam(data.team);
    },
    onError: () => {
      alert("error", "Failed to refresh team data.");
    },
  });

  useEffect(() => {
    if (auth?.isUserAuthenticated) {
      localStorage.setItem(
        "currentTeam",
        JSON.stringify(currentTeam),
      );
    }
  }, [currentTeam, auth?.isUserAuthenticated]);

  const refreshCurrentTeam = (team) => {
    //only refresh the team members and switch to the team
    //if the current user is on the team
    //since admins won't be on every team and we don't want
    //the dropdown (controlled input) to be undefined
    const isUserOnTeam = team?.users?.find(
      (user) => user.uuid == auth?.userUuid,
    );
    if (!isUserOnTeam) {
      return;
    }
    getTeam({ variables: { uuid: team?.uuid } });
  };

  return (
    <TeamContext.Provider
      value={{
        currentTeam,
        setCurrentTeam,
        refreshCurrentTeam,
        selectedTeamMembers,
        setSelectedTeamMembers,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
