import { useNoteMultiselectContext } from "../../../hooks";
import { TransferNotesModal } from "../modals/TransferNotesModal";

export function TransferMultiModal({
  notes,
  shouldShowTransferModal,
  setShouldShowTransferModal,
  confirmAction,
  loading,
  newUser,
  setNewUser,
  newTeam,
  setNewTeam,
}) {
  const { selectedNoteUuids } = useNoteMultiselectContext();

  return (
    <TransferNotesModal
      notes={notes}
      shouldShowTransferModal={shouldShowTransferModal}
      setShouldShowTransferModal={setShouldShowTransferModal}
      confirmAction={confirmAction}
      loading={loading}
      newUser={newUser}
      setNewUser={setNewUser}
      newTeam={newTeam}
      setNewTeam={setNewTeam}
      selectedNoteUuids={selectedNoteUuids}
    ></TransferNotesModal>
  );
}
