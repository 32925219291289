import { useDarkMode } from "@hooks";
import { useAuth } from "@hooks/use-auth";
import { useTeam } from "@hooks/use-team";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Chip,
} from "@mui/material";
import React from "react";

function TeamFilterDropdown() {
  const { currentTeam, selectedTeamMembers, setSelectedTeamMembers } =
    useTeam();
  const { userUuid } = useAuth();
  const handleSelectChange = (event) => {
    setSelectedTeamMembers(event.target.value);
  };

  const [enabled] = useDarkMode();
  if (!currentTeam?.users) {
    return null;
  }

  return (
    <>
      <FormControl>
        <InputLabel
          size="small"
          sx={[
            {
              "&.MuiInputLabel-shrink": {
                display: "none",
              },
            },
            {
              pl: 1,
              fontSize: "13px",
              color: enabled ? "rgb(209 213 219)" : "black",
            },
          ]}
          id="team-filter-dropdown-label"
        >
          Filter By Team Member(s)
        </InputLabel>
        <Select
          sx={[
            {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                pl: 0,
                py: 1,
              },
            },
            {
              fontSize: "14px",
              color: enabled ? "rgb(209 213 219)" : "black",
              backgroundColor: enabled
                ? "rgb(55 65 81)"
                : "rgb(243 244 246)",
              borderRadius: "8px",
              flex: 1,
              minWidth: 300,
              maxWidth: 600,
            },
          ]}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {currentTeam?.users?.map((user) => {
                if (selected.includes(user.uuid)) {
                  const userName = `${user.firstName} ${
                    user.lastName
                  } ${user.uuid === userUuid ? " (You)" : ""}`;
                  return (
                    <Chip
                      sx={{
                        height: "unset",
                        padding: "2px 0",
                        margin: "-2px 0",
                        color: enabled ? "rgb(209 213 219)" : "black",
                        borderRadius: 2,
                      }}
                      key={user.uuid}
                      label={userName}
                    />
                  );
                }
              })}
            </Box>
          )}
          className="mx-2 px-2"
          labelId="team-filter-dropdown-label"
          multiple={true}
          value={selectedTeamMembers}
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              style: {
                color: enabled ? "#f9fafb" : "black",
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
              },
            },
          }}
        >
          {currentTeam?.users?.map((user) => (
            <MenuItem
              key={user.uuid}
              value={user.uuid}
              sx={{
                fontSize: "14px",
              }}
            >
              <span className="text-sm font-medium truncate">
                {user.firstName} {user.lastName}{" "}
                {user.uuid === userUuid && " (You)"}
              </span>
            </MenuItem>
          ))}
          ;
        </Select>
      </FormControl>
    </>
  );
}

export default TeamFilterDropdown;
