import { useLazyQuery } from "@apollo/client";
import { GET_NOTE } from "@components/notes/EditNote.js";
import { mapRoleIdToRole } from "@components/team/EditExistingTeamMembers.js";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useLiveQuery } from "dexie-react-hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import {
  config,
  useTransition,
  useSpring,
  animated,
} from "react-spring";

import RecordingJobTypeSelection from "./RecordingJobTypeSelection.js";
import { RecordingSection } from "./RecordingSection";
import UnsyncedRecordings from "./UnsyncedRecordings.js";
import { UploadFinishedButton } from "./UploadFinishedButton.js";
import { uploadToCloud } from "../../../api/UploadAudio.js";
import { bytesToMB } from "../../../api/utils.js";
import { db } from "../../../db.js";
import { useTeam } from "../../../hooks";
import { useAccount, useLocalStorage } from "../../../hooks";
import { useDarkMode, useNoteUsage } from "../../../hooks";
import { useAuth } from "../../../hooks";
import { usePreventRecordingInterruptions } from "../../../hooks";
import NoteUsageWarningModal from "../../notes/common/NoteUsageWarningModal.js";
import { alert } from "../Alert.js";
import { SimpleNoteTransferModal } from "../modals/SimpleNoteTransferModal.js";

const PREVENT_NAVIGATION_MESSAGE =
  "You have unsaved changes. Are you sure you want to leave this page.";

function RecordingTitle({ control, percentCompleted }) {
  return (
    <div className="self-center w-full flex-1" id="recorderTitle">
      <Controller
        render={({ field }) => (
          <TextareaAutosize
            minRows={1}
            maxRows={1}
            placeholder={"📝 Note title (optional)"}
            disabled={percentCompleted < 100 && percentCompleted > 0}
            className="resize-none appearance-none relative block w-full px-2 py-[7px] border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm h-8 leading-normal"
            {...field}
          />
        )}
        control={control}
        name={"title"}
      />
    </div>
  );
}

const Record = ({
  createNewNote,
  id,
  isRecording,
  setIsRecording,
  control,
  shouldShowTitleInput = true,
  shouldUseSmallSize = false,
  shouldShowTypeDropdown = true,
  reset = () => {},
}) => {
  const [uploadStatus, setUploadStatus] = useState({
    numChunksTotal: 0,
    numChunksCompleted: 0,
  });
  const { useChunkedUpload } = useFlags();
  const uploadType = useChunkedUpload ? "chunked" : "single";
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noteToTransfer, setNoteToTransfer] = useState(null);
  const [shouldShowTransferModal, setShouldShowTransferModal] =
    useState(false);
  const [selectedJobType, setSelectedJobType] = useLocalStorage(
    "next-recording-type",
    "medical_record",
  );

  const { userUuid } = useAuth();

  const { currentTeam } = useTeam();
  const [getNote] = useLazyQuery(GET_NOTE, {
    fetchPolicy: "network-only",
  });
  const account = useAccount();
  const { reauthenticateActiveUser, checkIsTokenExpiringSoon } =
    useAuth();
  const [
    shouldShowUnsyncedRecordings,
    setShouldShowUnsyncedRecordings,
  ] = useState(false);

  const [noteUsageWarningLevel, setNoteUsageWarningLevel] =
    useState(null);

  const {
    numAppointmentsUsed,
    numAppointmentsAllowed,
    hasReachedAutoSoapLimit,
    shouldUseAutoSoaps,
  } = useNoteUsage();

  const { setShouldPreventInterruptions } =
    usePreventRecordingInterruptions();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const darkModeEnabled = useDarkMode();

  const allItems = useLiveQuery(() =>
    db.unsyncedRecordings.toArray(),
  );

  let trackColor = "#E4E4E7";
  if (darkModeEnabled[0]) {
    trackColor = "#1A2232";
  }

  function resetUploadStatus() {
    setUploadStatus({
      numChunksTotal: 0,
      numChunksCompleted: 0,
    });
  }

  const percentCompleted = uploadStatus.numChunksTotal
    ? Math.round(
        (uploadStatus.numChunksCompleted * 100) /
          uploadStatus.numChunksTotal,
      )
    : 0;
  const isFinishedUploading = percentCompleted === 100;

  const greenCheckAnim = useTransition(isFinishedUploading, {
    from: { opacity: 0, transform: "scale(0.8)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0.8)" },
    reverse: isFinishedUploading,
    config: config.wobbly,
    // onRest: () => setIsFinishedUploading(!isFinishedUploading),
  });

  const fadeButton = useSpring({
    config: config.wobbly,
    opacity: isFinishedUploading ? 0 : 1,
    transform: isFinishedUploading ? "scale(0.8)" : "scale(1)",
  });

  const eventListener = (event) => {
    if (isRecording) {
      event.preventDefault();
      return PREVENT_NAVIGATION_MESSAGE;
    } else {
      return null;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", eventListener);

    return () => {
      window.removeEventListener("beforeunload", eventListener);
    };
  }, [isRecording]);

  function pauseRecording() {
    try {
      mediaRecorderRef.current.pause();
      mixpanel.track("Pause Recording");
      setIsPaused(true);
    } catch (error) {
      mixpanel.track("Pause Recording Failed", {
        error: error.message,
      });
      alert(
        "error",
        "There was an error pausing the recording. Please try again.",
      );
    }
  }

  function startPausedRecording() {
    try {
      mediaRecorderRef.current.resume();
      mixpanel.track("Resume Recording");
      setIsPaused(false);
    } catch (error) {
      mixpanel.track("Resume Recording Failed", {
        error: error.message,
      });
      alert(
        "error",
        "There was an error resuming the recording. Please try again.",
      );
    }
  }

  async function checkAvailableDevices() {
    let audioAvailable = false;
    let videoAvailable = false;

    try {
      const devices = await Promise.race([
        // this should never take longer than a second, and if it does it's hanging forever
        navigator.mediaDevices.enumerateDevices(),
        new Promise((resolve, reject) => {
          setTimeout(
            () =>
              reject(
                "navigator.mediaDevices.enumerateDevices() timed out",
              ),
            5000,
          );
        }),
      ]);
      devices.forEach((device) => {
        if (device.kind === "audioinput") {
          audioAvailable = true;
        }
        if (device.kind === "videoinput") {
          videoAvailable = true;
        }
      });
    } catch (error) {
      mixpanel.track("Recording Error", { error });
      alert(
        "error",
        "Failed to get media info from browser. Try restarting your browser or switching to a different one.",
      );
    }

    return { audioAvailable, videoAvailable };
  }

  function noteWarningRequired() {
    if (account?.accountType === "unlimited") {
      return;
    }

    if (selectedJobType !== "medical_record" || !shouldUseAutoSoaps) {
      return;
    }

    if (hasReachedAutoSoapLimit) {
      return "limit";
    }

    if (!numAppointmentsUsed || !numAppointmentsAllowed) {
      return;
    }

    const appointmentsUsedRatio =
      numAppointmentsUsed / numAppointmentsAllowed;
    const warningLevel = 0.8;

    if (appointmentsUsedRatio >= warningLevel) {
      if (localStorage.getItem("note_usage_warning_shown")) {
        return;
      }
      // only show this warning once
      localStorage.setItem("note_usage_warning_shown", true);
      return "warning";
    }
  }

  async function startRecording(ignoreModal = false) {
    if (isRecording) {
      mixpanel.track(
        "Attempted to start a recording with another recording in progress",
      );
      return;
    }
    if (!ignoreModal) {
      const warningLevel = noteWarningRequired();
      if (warningLevel) {
        setNoteUsageWarningLevel(warningLevel);
        return;
      }
    }
    //check if the user's token will expire soon
    const isTokenExpiringSoon = await checkIsTokenExpiringSoon();
    if (isTokenExpiringSoon) {
      await reauthenticateActiveUser();
    }
    setIsLoading(true);

    mixpanel.track("Start Recording");

    const constraints = {
      audio: true,
      video: false,
    };

    try {
      //Check available devices before requesting the media stream
      const { audioAvailable, videoAvailable } =
        await checkAvailableDevices();

      if (!audioAvailable) {
        //Inform the user that no microphone is available
        alert(
          "error",
          "No audio input device found. Please connect a microphone.",
        );
        mixpanel.track("Recording Error", {
          error: "No audio input device found",
          audioAvailable,
          videoAvailable,
        });
        setIsLoading(false);
        return;
      }

      //Proceed with requesting the media stream
      let stream = null;

      try {
        stream = await navigator.mediaDevices.getUserMedia(
          constraints,
        );
      } catch (error) {
        alert(
          "error",
          "You need to allow microphone permissions to record audio.",
        );
        setIsLoading(false);
        return;
      }

      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.start();

      mediaRecorderRef.current.onstart = function () {
        setIsRecording(true);
        setShouldPreventInterruptions(true);
        setIsLoading(false);
      };
    } catch (error) {
      const { audioAvailable, videoAvailable } =
        await checkAvailableDevices();

      mixpanel.track("Recording Error", {
        error: error.message || error.name,
        audioAvailable,
        videoAvailable,
      });

      alert(
        "error",
        "There was an error starting the recording. Please check your microphone and try again.",
      );
    }
  }

  function cancelRecording() {
    return stopRecording(true);
  }

  function stopMediaRecorder() {
    let mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      mixpanel.track("Attempted to stop a nonexistent recording");
    }
    mediaRecorder.stop();

    // Release the tracks, otherwise the browser will still be using the microphone
    for (const track of mediaRecorder.stream.getTracks()) {
      track.stop();
    }
  }

  function discardRecording() {
    setIsRecording(false);
    setShouldPreventInterruptions(false);
    setIsPaused(false);
    clearRefs();
  }

  function clearRefs() {
    audioChunksRef.current = [];
    streamRef.current = null;
    mediaRecorderRef.current = null;
  }

  function handleIndexedDBError(error, blob, jobType) {
    const errorType = error?.name || "Unknown Error";
    let alertMessage;
    let mixpanelMessage;

    switch (errorType) {
      case "QuotaExceededError":
        alertMessage =
          "Storage limit exceeded. Please delete some recordings to free up space.";
        mixpanelMessage = "Storage limit exceeded.";
        break;

      case "SecurityError":
        alertMessage =
          "Storage access denied. Please enable cookies or site permissions.";
        mixpanelMessage = "Storage access denied.";
        break;

      default:
        alertMessage =
          "An unknown error occurred while saving the recording. Try again.";
        mixpanelMessage = error.message || "Unknown error.";
    }

    alert("error", alertMessage);

    mixpanel.track("IndexedDB Error", {
      error: errorType,
      message: mixpanelMessage,
      fileSizeMB: blob ? bytesToMB(blob.size).toFixed(2) : "N/A",
      jobType,
    });
  }

  async function maybePromptTransfer(noteUuid) {
    if (!noteUuid || !currentTeam?.users?.length) {
      return;
    }
    const teamUser = currentTeam.users.find(
      (user) => user.uuid === userUuid,
    );
    const userRole = mapRoleIdToRole(teamUser?.roleId);
    const promptNewNoteTransfer = localStorage.getItem(
      "should-prompt-new-note-transfer",
    );
    if (promptNewNoteTransfer === null && userRole === "Technician") {
      localStorage.setItem("should-prompt-new-note-transfer", true);
    }
    if (localStorage.getItem("should-prompt-new-note-transfer")) {
      const { data } = await getNote({
        variables: {
          uuid: noteUuid,
        },
      });
      setNoteToTransfer(data.note);
      setShouldShowTransferModal(true);
    }
  }

  async function uploadRecording() {
    const blob = new Blob(audioChunksRef.current, {
      type: "audio/wav",
    });
    clearRefs();

    let id;

    try {
      id = await db.unsyncedRecordings.add({
        saved_date: moment().format(),
        is_synced: false,
        note_uuid: null,
        blob,
        jobType: selectedJobType,
      });

      mixpanel.track("Recording Saved to IndexedDB", {
        recordingId: id,
        timestamp: new Date().toISOString(),
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        jobType: selectedJobType,
      });
    } catch (error) {
      handleIndexedDBError(error, blob, selectedJobType);
      return;
    }

    // Upload the recording to the cloud
    try {
      // Attempt uploadToCloud
      const response = await uploadToCloud({
        blob,
        setUploadStatus,
        createNewNote,
        jobType: selectedJobType,
        uploadType,
      });

      if (response?.success) {
        maybePromptTransfer(response?.note?.uuid);
        try {
          // If upload is successful, try to delete the recording from IndexedDB
          await db.unsyncedRecordings.delete(id);

          mixpanel.track("Recording Uploaded Successfully", {
            recordingId: id,
            timestamp: new Date().toISOString(),
          });
        } catch (dbError) {
          // Handle errors from IndexedDB deletion
          mixpanel.track("IndexedDB Delete Failed", {
            recordingId: id,
            error: dbError.message || dbError,
          });

          handleIndexedDBError(dbError, blob, selectedJobType);
          throw new Error(
            "Failed to delete recording from IndexedDB.",
          );
        }
      } else {
        // If the error has to do with uploadToCloud and not IndexedDB, handle as such
        throw new Error("Cloud upload failed.");
      }
    } catch (error) {
      // Capture errors from the cloud upload or the IndexedDB delete failure
      mixpanel.track("Recording Sync Failed", {
        recordingId: id,
        error: error.message || error,
        jobType: selectedJobType,
      });

      alert(
        "warning",
        "An error occurred while uploading your recording. It has been saved offline for now.",
      );
    } finally {
      setIsRecording(false);
      setShouldPreventInterruptions(false);
      reset({ title: "" });
    }
  }

  function stopRecording(discard = false) {
    setIsPaused(false);
    resetUploadStatus();
    if (!mediaRecorderRef.current) {
      mixpanel.track(
        "Attempted to stop a recording with no recording",
      );
      return;
    }
    mixpanel.track("Stop Recording", {
      recordingRef: mediaRecorderRef.current,
      discard,
    });
    if (discard) {
      mediaRecorderRef.current.onstop = discardRecording;
    } else {
      mediaRecorderRef.current.onstop = uploadRecording;
    }
    stopMediaRecorder();
  }

  return (
    <div className="h-full flex flex-col items-center">
      <div className="w-full flex flex-row items-start space-x-2">
        <NoteUsageWarningModal
          warningLevel={noteUsageWarningLevel}
          setWarningLevel={setNoteUsageWarningLevel}
          startRecording={startRecording}
        />

        <SimpleNoteTransferModal
          shouldShowTransferModal={shouldShowTransferModal}
          setShouldShowTransferModal={setShouldShowTransferModal}
          note={noteToTransfer}
        ></SimpleNoteTransferModal>

        {shouldShowTitleInput ? (
          <RecordingTitle
            control={control}
            percentCompleted={percentCompleted}
          />
        ) : null}
        {shouldShowTypeDropdown && shouldUseAutoSoaps ? (
          <RecordingJobTypeSelection
            selectedJobType={selectedJobType}
            setSelectedJobType={setSelectedJobType}
          />
        ) : null}
      </div>

      <div
        className={`flex flex-col ${
          shouldUseSmallSize
            ? "w-44 h-44 md:h-52 md:w-52"
            : "h-60 w-60"
        } justify-center items-center mt-16`}
      >
        {isFinishedUploading ? (
          <UploadFinishedButton
            trackColor={trackColor}
            transitions={greenCheckAnim}
            resetUploadStatus={resetUploadStatus}
          />
        ) : (
          <animated.div
            style={fadeButton}
            className="w-full h-full flex items-center justify-center"
          >
            <RecordingSection
              id={id}
              isRecording={isRecording}
              isPaused={isPaused}
              isLoading={isLoading}
              audio={streamRef.current}
              percentCompleted={percentCompleted}
              isFinishedUploading={isFinishedUploading}
              startRecording={startRecording}
              stopRecording={stopRecording}
              pauseRecording={pauseRecording}
              cancelRecording={cancelRecording}
              startPausedRecording={startPausedRecording}
              trackColor={trackColor}
            />
          </animated.div>
        )}
      </div>
      <div className="relative mt-12">
        {allItems?.length > 0 ? (
          <>
            <button
              onClick={() => setShouldShowUnsyncedRecordings(true)}
              className="rounded-full shadow-md bg-indigo-500  self-center py-1.5 px-3 text-white font-medium hover:bg-indigo-600 focus:outline-none transition-all"
            >
              Sync Recordings
              <div className="absolute -top-2 -right-3 h-6 min-w-[24px] px-1 bg-red-400 flex items-center justify-center rounded-full">
                {allItems?.length}
              </div>
            </button>
            {shouldShowUnsyncedRecordings ? (
              <UnsyncedRecordings
                shouldShowUnsyncedRecordings={
                  shouldShowUnsyncedRecordings
                }
                setShouldShowUnsyncedRecordings={
                  setShouldShowUnsyncedRecordings
                }
                createNewNote={createNewNote}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Record;
